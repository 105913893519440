import PropTypes from 'prop-types'
import { useId, useMemo } from 'react'
import { Maybe } from 'monet'
import { styled } from '@mui/material/styles'
import { componentShape } from 'core/shapes'
import Form from 'hss/ContentBuilder/interactives/Form'
import { CONTENT_STATE_DRAFT } from 'core/consts'
import { get } from 'fp/objects'
import { isBlock } from 'selectors/content'
import { formPropsShape } from '../utils'
import LinkToViewer from './Block/LinkToViewer'

const Styled = styled(
  'div',
  { name: 'Curriculum-CurriculumForm' },
)(({ theme: { palette } }) => ({
  a: { color: palette.cobalt[0] },
}))

const defaultValues = {
  contentState: CONTENT_STATE_DRAFT,
  subtitle: '',
  teacherEdition: false,
  pullQuote: '',
}

const CurriculumForm = ({ children, defaultProps: additionalDefaultProps, formProps, sx, ...rest }) => {
  const idForUndefinedContent = useId()

  const handleSuccess = ({ response }, { setValue }) => {
    /**
     * This updates the ChildList component that each content row has (besides
     * block, which has no children)
     *
     * This is done to keep the form data in sync with the content data. When a
     * new row is added to the ChildList component, we don't have an id for it.
     * Once the user saves we do get back the id in the payload, so we can just
     * shove it back into the form values to get things to sync up.
     *
     * This payload response is raw and so will have a nested `children` collection,
     * but we don't need to do any cleanup here as the api only looks at the root
     * level and even then is looking only for ids.
     */
    if (!isBlock(response)) {
      setValue('children', response.children)
    }
  }

  /**
   * This forces the Form to rerender whenever the content id or child content
   * items changes.  This became an issue when you could route to a section FROM
   * a section; since the router would not remount the existing components, and
   * since hook-form does not like (or want) to update the default form data
   * once mounted.
   *
   * I suspect this may also have been the cause of some the occasional weirdness
   * we'd see when navigating ChildLists
   */
  const key = useMemo(() => Maybe.fromUndefined(formProps.content)
    .map(({ id, children: newChildren }) => id + newChildren
      .map(get('id'))
      .join())
    .orJust(idForUndefinedContent), [formProps.content, idForUndefinedContent])

  return (
    <Styled>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <LinkToViewer content={formProps.content} />

      <Form
        assetCodeRequired={false}
        defaultValues={{
          ...additionalDefaultProps,
          ...defaultValues,
        }}
        formProps={formProps}
        framelessAllowed={false}
        interactiveInstructionsAllowed={false}
        key={key}
        name="CurriculumForm"
        nameRequired
        onSuccess={handleSuccess}
        showCaptionPosition={false}
        studentInstructionsAllowed={false}
        vocabPhrasesAllowed={false}
        {...rest}
        sx={{
          ...sx,
          '.tr-headline--size-4, .MuiAccordion-root': {
            marginTop: 3,
          },
        }}
      >

        {children}

      </Form>
    </Styled>
  )
}

CurriculumForm.propTypes = {
  children: componentShape.isRequired,
  defaultProps: PropTypes.object,
  formProps: formPropsShape.isRequired,
  sx: PropTypes.object,
}

export default CurriculumForm
