import VennDiagramInteractive from 'hss/sections/contentBlocks/interactives/VennDiagram'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_VENN_DIAGRAM } from 'core/consts'
import withContent from 'hoc/withContent'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import { QuestionPromptWithHeadline } from '../Form/QuestionPrompt'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_VENN_DIAGRAM
const Interactive = VennDiagramInteractive
const defaultValues = {
  circles: 2,
  readOnly: false,
  studentHeadlineEditing: false,
  studentBottomCircle: false,
  sections: [
    { header: '', response: '', circleIds: [1] },
    { header: '', response: '', circleIds: [2] },
    { header: '', response: '', circleIds: [1, 2] }],
}

const additionalInteractiveProps = {
  hideSampleResponse: true,
}

const previewButton = props => <PreviewButton {...{ additionalInteractiveProps, Interactive, ...props }} />

const VennDiagram = formProps => (
  <Form
    fullWidth
    name="Interactive-VennDiagram"
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    preventEnterSubmits={false}
  >
    <QuestionPromptWithHeadline name="prompt" />
    <Content />
  </Form>
)
const VennDiagramWithContent = withContent(VennDiagram, { contentType: CONTENT_TYPE_INTERACTIVE })

export default VennDiagramWithContent
